import theme from '@/style';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import {BasicObject} from '@/types';

export interface DigitBallProps {
  ballSize?: number;
  digit: string;
  color: string;
  fontSize?: number;
  emptyType?: string;
}

const COLOR_MAP = {
  A: '#DE3C3F',
  B: '#EC8305',
  C: '#046FEA',
} as BasicObject;

const DigitBall = ({
  ballSize = 32,
  digit,
  color = 'A',
  fontSize = 12,
  emptyType = '*',
}: DigitBallProps) => {
  return (
    <View
      style={[
        theme.flex.center,
        // eslint-disable-next-line react-native/no-inline-styles
        {
          width: ballSize,
          height: ballSize,
          borderRadius: ballSize / 2,
          backgroundColor:
            digit === '-' && emptyType === '*' ? '#C6C6C6' : COLOR_MAP[color],
        },
      ]}>
      <Text fontSize={fontSize} fontFamily="fontInterBold" color={'white'}>
        {digit}
      </Text>
    </View>
  );
};

export default DigitBall;
