/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import DigitBall from '../../components/digit-ball';

const ResultItem = ({
  index = 0,
  issueNum,
  time,
  result,
  emptyType = '*',
}: {
  index?: number;
  result?: string;
  issueNum?: string;
  time?: string;
  isQuick?: boolean;
  emptyType?: string;
}) => {
  const backgroundColor = React.useMemo(() => {
    return {
      backgroundColor: index % 2 === 0 ? '#262B33' : 'var(--button-s)',
    };
  }, [index]);

  const resultDigit = React.useMemo(() => {
    if (result) {
      return result.split('');
    }
    return new Array(3).fill(emptyType);
  }, [result, emptyType]);

  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.centerByCol,
        styles.itemContainer,
        theme.margin.lrl,
        backgroundColor,
      ]}>
      <View style={[theme.flex.flex1]}>
        <Text>{issueNum}</Text>
      </View>
      <View style={[theme.flex.flex1, theme.flex.centerByCol]}>
        <Text textAlign="center">{time}</Text>
      </View>
      <View style={[theme.flex.row, {gap: 4}]}>
        {['A', 'B', 'C'].map((item, _i) => (
          <DigitBall
            ballSize={24}
            digit={resultDigit[_i]}
            emptyType={emptyType}
            color={item}
            key={_i}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  itemContainer: {
    height: 48,
    paddingHorizontal: 12,
  },
});

export default ResultItem;
