import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import React from 'react';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import CloseCircle from '@/components/svgs/basic/close-circle';

const BetsItem = ({
  type = '',
  digit = '',
  rate = 3,
  onClose = () => {},
}: {
  type?: string;
  digit?: string;
  rate?: number;
  onClose?: () => void;
}) => {
  return (
    <View style={styles.container}>
      <Text size="large" color={'var(--T2-dark)'} fontFamily="fontInterBold">
        {type}={digit}
      </Text>
      <View style={[theme.margin.lefts, styles.rateContainer]}>
        <Text color={'white'} fontSize={10} fontFamily="fontInterBold">
          x{rate}
        </Text>
      </View>
      <NativeTouchableOpacity style={[styles.close]} onPress={onClose}>
        <CloseCircle />
      </NativeTouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'var(--button-s)',
    flexDirection: 'row',
    alignItems: 'center',
    height: 36,
    borderRadius: 18,
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  rateContainer: {
    height: 16,
    borderRadius: 4,
    paddingHorizontal: 4,
    justifyContent: 'center',
    backgroundColor: theme.backgroundColor.orange,
  },
  close: {
    position: 'absolute',
    right: 0,
    top: -8,
  },
});

export default BetsItem;
