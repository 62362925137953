/* eslint-disable react-native/no-inline-styles */
import {View} from 'react-native';
import QuickBtn from './quick-btn';
import Price from '@/components/business/price';
import Text from '@/components/basic/text';
import theme from '@/style';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import React from 'react';
import {BasicObject} from '@/types';
import {useTranslation} from 'react-i18next';

const LEVEL_MAP = {
  1: 'single',
  2: 'double',
  3: 'triple',
} as BasicObject;

export interface BetsHeaderProps {
  winPrize?: number;
  level?: number;
  payment?: number;
  onGuess?: () => void;
}

const BetsHeader = (props: BetsHeaderProps) => {
  const {winPrize = 0, payment = 1, level = 1, onGuess} = props;

  const title = React.useMemo(() => {
    if (level) {
      return LEVEL_MAP[level];
    }
    return '';
  }, [level]);

  const {i18n} = useTranslation();

  return (
    <View style={[theme.flex.row, theme.flex.centerByCol, theme.flex.between]}>
      <View>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <View
            style={[
              theme.flex.row,
              theme.flex.centerByCol,
              theme.margin.rightxxs,
            ]}>
            <Text size="large" fontFamily="fontInterBold" color={'var(--T1)'}>
              {title ? i18n.t(`game-page.label.${title}`) : ''}
            </Text>
            <Text
              size="large"
              fontFamily="fontInterBold"
              color="var(--T2-dark)">
              {' '}
              {i18n.t('game-page.label.digit')}
            </Text>
          </View>
          <LinearGradient
            colors={['#E47A3E', 'rgba(255, 153, 96, 0.00)']}
            start={{
              x: 0,
              y: 0,
            }}
            end={{
              x: 1,
              y: 0,
            }}
            style={[
              theme.flex.row,
              theme.flex.center,
              theme.padding.lrxxs,
              {height: 20, borderBottomLeftRadius: 8},
            ]}>
            <Text
              style={[theme.margin.rightxxs]}
              size="small"
              fontFamily="fontInterBold"
              main>
              {i18n.t('game-page.label.win')}
            </Text>
            <Price
              textProps={{
                size: 'large',
                main: true,
              }}
              price={winPrize}
            />
          </LinearGradient>
        </View>
        <Price
          price={payment}
          textProps={{
            size: 'medium',
            color: 'var(--T1)',
            fontFamily: 'fontInterBold',
          }}
        />
      </View>
      <QuickBtn onPress={onGuess} />
    </View>
  );
};

export default BetsHeader;
