import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {StyleProp, StyleSheet, ViewStyle} from 'react-native';
export interface BetsButtonProps {
  title?: string;
  disabled?: boolean;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
}

const BetsButton = ({
  title = '',
  style = {},
  disabled = false,
  onPress = () => {},
}: BetsButtonProps) => {
  return (
    <NativeTouchableOpacity
      onPress={onPress}
      disabled={disabled}
      style={[
        theme.background.primary,
        theme.flex.center,
        styles.button,
        // eslint-disable-next-line react-native/no-inline-styles
        {opacity: disabled ? 0.3 : 1},
        style,
      ]}>
      <Text color={'white'} fontFamily="fontInterBold" size="medium">
        {title}
      </Text>
    </NativeTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 40,
    borderRadius: 20,
    elevation: 3,
    paddingHorizontal: 20,
  },
});

export default BetsButton;
