import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/basic/spin';
import PageNumber from '@/components/business/games/page';
import OrderCard from '@/components/business/order/order-card';
import theme from '@/style';
import dayjs from 'dayjs';
import React from 'react';
import DrawResult from './draw-result';
import OrderBetList from './order-bet-list';
import {
  DigitOrderItem,
  getDigitOrderList,
  quickGameOrder,
} from '../../digit-service';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {copyText, getUUID, goTo, transfor} from '@/utils';
import {View} from 'react-native';
import Drawer, {DrawerRef} from '@/components/basic/game-drawer/game-drawer';
import {getShareAward} from '@/services/global.service';
import SharePanel from '@/components/business/share-panel/share-panel';
import {shareToThird, shareToChat} from '@/common-pages/bets/bets.service';
import {IShareInfo} from '@/common-pages/bets/bets.service';
import {useShareToast} from '@/common-pages/hooks/shareToast.hooks';
import {SafeAny} from '@/types';

const pageSize = 3;

const OrderList = ({
  type = '',
  pickThreeId,
  refreshCode = 0,
}: {
  type?: string;
  pickThreeId?: number;
  isActive?: boolean;
  refreshCode?: number;
}) => {
  const {shareSuccess, shareFail} = useShareToast();
  const {i18n} = useTranslation();
  const [total, setTotal] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [list, setList] = React.useState<DigitOrderItem[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [initPage, setInitPage] = React.useState(1);
  const [shareAward, setShareAward] = React.useState<number>(0);
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});
  const panelRef = React.useRef<DrawerRef>(null);

  // pageNo: number = 1,
  // pageSize = 10,
  // orderStatus = 3,
  // yearMonth?: string | number, // 格式 202311 到月
  // yearMonthDay?: string | number,

  const getList = async (page: number = 1) => {
    try {
      setLoading(true);
      const res = await getDigitOrderList(page, pageSize);
      if (res) {
        const {totalSize, content} = res;
        setTotal(totalSize);
        setList(content);
        const award = await getShareAward(
          type === 'quick' ? 'quick3d' : '3Digit',
        );
        setShareAward(Math.floor(award));
      }
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  React.useEffect(() => {
    if (type === 'normal') {
      if (!globalStore.token) {
        goTo('Login');
        setFirstLoad(false);
        return;
      }
      getList().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (pickThreeId && type === 'quick') {
      if (!globalStore.token) {
        goTo('Login');
        setFirstLoad(false);
        return;
      }
      getQuickOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickThreeId]);

  React.useEffect(() => {
    if (refreshCode > 0) {
      if (!globalStore.token) {
        return;
      }
      if (type === 'quick') {
        getQuickOrder().then();
        setInitPage(1);
      } else {
        getList().then();
        setInitPage(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshCode]);

  const getQuickOrder = async (page: number = 1) => {
    try {
      if (pickThreeId) {
        setLoading(true);
        const res = await quickGameOrder({
          pickThreeId,
          pageNo: page,
          pageSize,
          orderStatus: 3,
        });
        if (res) {
          const {totalCount, records} = res;
          setTotal(totalCount);
          setList(records || []);
        }
      }
    } finally {
      setLoading(false);
      setFirstLoad(false);
    }
  };

  const onCopy = (id: string) => {
    copyText(id);
    globalStore.globalSucessTotal(i18n.t('share.copy-success'));
  };

  return (
    <>
      <Spin loading={firstLoad || loading} style={[theme.flex.flex1]}>
        {!firstLoad && !loading && list && list.length === 0 ? (
          <NoData />
        ) : null}
        {list.map((item, _i) => (
          <View key={_i}>
            <OrderCard
              id={item.orderGroup}
              gameName={item.timeName}
              payment={item.totalAmount}
              awardAmount={item.winAmount}
              drawTime={dayjs(item.gameDrawTime).format('DD-MM hh:mm A')}
              betsTime={dayjs(item.createTime).format('DD-MM-YYYY hh:mm A')}
              onCopy={() => onCopy(item.orderGroup)}
              result={
                <DrawResult
                  drawTime={item.gameDrawTime}
                  wonCode={item.wonCode}
                />
              }
              drawContainerStyle={{backgroundColor: 'var(--card)'}}
              hideDraw={false}
              status={
                item?.wonCode.startsWith('*') ? 2 : item.winAmount > 0 ? 1 : 0
              }
              onShare={() => {
                const user = localStorage.getItem('user') || '{}';
                const userId = JSON.parse(user).userId;
                setThreeOrderData({
                  userId,
                  data: transfor(
                    item,
                    type === 'quick' ? 'quick3d' : '3Digit',
                    {
                      from: 'order',
                    },
                  ),
                });
                panelRef.current?.open();
              }}
              hasAward={Boolean(shareAward)}
            />
            <OrderBetList codeLists={item.codeLists} />
          </View>
        ))}
        {total > pageSize ? (
          <PageNumber
            page={initPage}
            total={total}
            pageSize={pageSize}
            onPageChange={p => {
              setInitPage(p);
              type === 'normal' ? getList(p).then() : getQuickOrder(p).then();
            }}
          />
        ) : null}
      </Spin>
      <Drawer mode="bottom" ref={panelRef} contentBackgroundColor="transparent">
        <SharePanel
          gameType={type === 'quick' ? 'quick3d' : '3Digit'}
          hasReward={Boolean(shareAward)}
          onClose={() => panelRef.current?.close()}
          onItemPress={pt => {
            panelRef.current?.close();
            shareToThird(
              pt,
              {
                gameCode: type === 'quick' ? 'quick3d' : '3Digit',
                orderNo: getUUID(),
                threeOrderData,
              },
              (info: IShareInfo) => shareToChat(info),
              () => {
                shareSuccess(shareAward);
                setShareAward(0);
              },
              shareFail,
            );
          }}
        />
      </Drawer>
    </>
  );
};

export default React.memo(OrderList);
