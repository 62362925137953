import globalStore from '@/services/global.state';
import theme from '@/style';
import React from 'react';
import {TextInput, View, StyleSheet} from 'react-native';

export interface NumberInputProps {
  value?: string;
  onChange?: (value: string) => void;
  inputStatus?: boolean;
}

export interface NumberInputRef {
  inputRef: React.RefObject<TextInput>;
}

const NumberInput = React.forwardRef<NumberInputRef, NumberInputProps>(
  ({value = '', onChange = () => {}}: NumberInputProps, ref) => {
    const inputRef = React.useRef<TextInput>(null);
    React.useImperativeHandle(ref, () => ({
      inputRef: inputRef,
    }));
    return (
      <View style={styles.container}>
        <TextInput
          ref={inputRef}
          textAlign="center"
          value={value}
          placeholder="-"
          maxLength={1}
          multiline={true}
          placeholderTextColor={'#9FA5AC'}
          numberOfLines={1}
          focusable={false}
          onChangeText={t => {
            const regex = /^[0-9]*$/;
            if (regex.test(t)) {
              onChange(t);
            }
          }}
          keyboardType="numeric"
          style={[
            styles.input,
            globalStore.isWeb && {lineHeight: theme.iconSize.xxl - 2},
          ]}
        />
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    width: 32,
    height: 32,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: 'var(--game-line)',
    backgroundColor: 'var(--button-s)',
  },
  input: {
    padding: 0,
    flex: 1,
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.fontColor.main,
    fontFamily: 'fontInterBold',
  },
});

export default NumberInput;
